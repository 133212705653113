<template>
	<b-modal
		id="forgotPassword-modal"
		class="defaultModal"
		centered
		hide-footer
		:title="t('route.forgotpassword')"
		v-model="showModal"
		@show="initModal"
		@hidden="endModal"
	>
		<!-- ================= STEP 1 ================= -->
		<form v-if="currentProgress == 1" class="fieldContainerModal" @submit.prevent="onSubmitPhone" novalidate>
			<InputPhone
				v-model="phoneNo"
				:countryCode="countryCode"
				:label="t('common.phoneNumber')"
				:required="true"
				:errors="v$.account.phoneNo.$errors"
			/>
			<div class="buttonsContainer">
				<button type="submit" class="mainButton">
					{{ t("common.submit") }}
				</button>
			</div>
		</form>
		<!-- ================= STEP 1 ================= -->

		<!-- ================= STEP 2 ================= -->

		<form v-if="currentProgress == 2" class="fieldContainerModal" @submit.prevent="onSubmit" novalidate>
			<InputOtp
				v-model="tacCode"
				:label="t('common.verificationCode')"
				:required="true"
				:errors="v$.tac.tacCode.$errors"
			/>

			<div class="buttonsContainer">
				<button type="button" class="backButton unstyledButton" @click="updateProgress('prev')">
					{{ t("common.back") }}
				</button>

				<!-- "key" directive helps refresh the ".once" event modifier -->
				<!-- once the coundown starts -->
				<button
					type="button"
					class="secondaryButton"
					:disabled="timeRemaining"
					@click.once="requestTAC(true)"
					:key="timeRemaining"
				>
					{{ timeRemaining ? t("common.resendInSeconds", { time: timeRemaining }) : t("common.resendTac") }}
				</button>

				<button type="submit" class="mainButton">
					{{ t("common.submit") }}
				</button>
			</div>
		</form>

		<!-- ================= STEP 2 ================= -->
		<!-- <template #footer> </template> -->
	</b-modal>
</template>

<script setup>
import { ref, computed, watch, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useLoading } from "vue-loading-overlay";
import { useToast } from "vue-toastification";
import { useVuelidate } from "@vuelidate/core";
import { toastOptionSuccess, toastOptionError } from "@/composables/useToastOptions";
import { minLength, maxLength, numeric, required, helpers } from "@vuelidate/validators";
import { routeData } from "@/constant";
import { countryData } from "@/composables/useCountry";
import InputPhone from "@/components/dynamic/Inputs/InputPhone.vue";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
import InputOtp from "@/components/dynamic/Inputs/InputOtp.vue";

const { t, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const $loading = useLoading();
const toast = useToast();

// ================= Progress Start =================

const currentProgress = ref(1);
const progressList = ref([
	{
		step: 1,
		name: t("route.login"),
	},
	{
		step: 2,
		name: t("common.tac"),
	},
]);
const updateProgress = (direction) => {
	if (direction == "next" && currentProgress.value < progressList.value.length) currentProgress.value += 1;
	if (direction == "prev" && currentProgress.value > 1) currentProgress.value -= 1;
};

// ================= Progress End =================

// ================= TAC Start =================

const tacCode = ref("");

const requestTAC = async () => {
	return await store
		.dispatch("register/requestTAC", {
			phoneNo: phoneNo.value,
			password: "",
		})
		.then(() => {
			toast.success(t("toast.tacSuccess"), toastOptionSuccess);
			startCountdown();
		})
		.catch(() => {
			toast.error(t("toast.tacFailed"), toastOptionError);
			startCountdown(5);
		});
};

const TAC_COOLDOWN = 60;
const timeRemaining = ref(0);

const startCountdown = async (cooldown) => {
	timeRemaining.value = cooldown || TAC_COOLDOWN;
	const timer = setInterval(() => {
		timeRemaining.value -= 1;
		if (timeRemaining.value <= 0) clearInterval(timer);
	}, 1000);
};

// ================= TAC End =================

// ================= Form Start =================

const country = ref("MY");
const countryCode = computed(() => countryData[country.value].COUNTRY_CODE);

const phoneNo = ref("");

const onSubmitPhone = async () => {
	const validity = await v$.value.account.$validate();
	if (!validity) return;
	const loader = $loading.show();
	await requestTAC();
	currentProgress.value = 2;
	loader.hide();
};

const onSubmit = async () => {
	const validity = await v$.value.tac.$validate();
	if (!validity) return;

	const loader = $loading.show();
	const result = await forgotPassword();
	if (result?.url) {
		endModal();
		window.open(result?.url, "_self");
	}
	loader.hide();
	return;
};

const forgotPassword = async () => {
	return await store
		.dispatch("member/forgotPassword", {
			phoneNo: phoneNo.value,
			byPassSms: true,
			tokenOTP: tacCode.value,
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			toast.error(t("toast.tacVerificationFailed"), toastOptionError);
			return false;
		});
};

const clearForm = async () => {
	phoneNo.value = "";
	tacCode.value = "";

	await nextTick();
	v$.value.$reset();
};

// ================= Form End =================

// ================= Validation Start =================

const TAC_LENGTH = 6;

const rules = computed(() => ({
	account: {
		phoneNo: {
			required: helpers.withMessage(() => t("validation.required"), required),
			numeric: helpers.withMessage(() => t("validation.numeric"), numeric),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(countryData[country.value].MIN_PHONE_LENGTH)
			),
			maxLength: helpers.withMessage(
				({ $params }) => t("validation.maxLength", { maxLength: $params.max }),
				maxLength(countryData[country.value].MAX_PHONE_LENGTH)
			),
		},
	},
	tac: {
		tacCode: {
			required: helpers.withMessage(() => t("validation.required"), required),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(TAC_LENGTH)
			),
		},
	},
}));

const v$ = useVuelidate(rules, { account: { phoneNo }, tac: { tacCode } });

// ================= Validation End =================

// ================= Modal Control Start =================

const showModal = ref(false);
const hideModal = () => {
	showModal.value = false;
};

const initModal = async () => {
	return;
};

const endModal = () => {
	clearForm();
	hideModal();
	currentProgress.value = 1;
	return;
};

// ================= Modal Control End =================
</script>

<style scoped lang="sass">
.buttonsContainer
	display: flex
	flex-direction: column
	gap: 1rem
	margin-top: 1rem
</style>
