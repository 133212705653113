<template>
	<form class="headerLoginForm" @submit.prevent="onSubmitLogin" novalidate>
		<InputPhone
			v-model="phoneNo"
			:countryCode="countryCode"
			:placeholder="t('common.EnterYourPhoneNumber')"
			:required="true"
			:errors="v$.account.phoneNo.$errors"
		/>

		<InputPassword
			v-model="password"
			:placeholder="t('common.EnterYourPassword')"
			:required="true"
			:errors="v$.account.password.$errors"
		/>

		<button type="submit" class="secondaryButton loginButton">
			{{ t("route.login") }}
		</button>

		<UnifiedLink :isModal="register.isModal" :modalName="register.modalName" :guestOnly="register.guestOnly">
			<button class="mainButton registerButton" type="button">
				{{ t("route.register") }}
			</button>
		</UnifiedLink>
	</form>

	<TACModal :loginId="phoneNo" :isMigrated="isMigrated" @reLogin="onSubmitLogin" ref="tacModal" />
</template>

<script setup>
import { ref, computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { routeData } from "@/constant";
import { useIsMobile } from "@/composables/useMobile";
import { languageCodeToId } from "@/composables/useCulture";
import { country, countryData } from "@/composables/useCountry";
import { useLoading } from "vue-loading-overlay";
import { toastOptionSuccess, toastOptionError } from "@/composables/useToastOptions";
import { useToast } from "vue-toastification";
import { authField } from "@/composables/useAuth";
import { useVuelidate } from "@vuelidate/core";
import { minLength, maxLength, numeric, required, helpers } from "@vuelidate/validators";
import InputPhone from "@/components/dynamic/Inputs/InputPhone.vue";
import InputPassword from "@/components/dynamic/Inputs/InputPassword.vue";
import store from "@/store/index";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
import { useRouter, useRoute } from "vue-router";
import TACModal from "@/components/modal/TACModal.vue";

const { t, locale } = useI18n();
const isMobile = useIsMobile();
const $loading = useLoading();
const toast = useToast();
const router = useRouter();
const route = useRoute();

const imgUrlDomain = process.env.VUE_APP_Media_Domain;

const register = routeData.find((x) => x.title == "register");

const tacModal = ref(null);

// ================= Form Start =================

const countryCode = computed(() => countryData[country].COUNTRY_CODE);

const phoneNo = ref("");
const password = ref("");
const isMigrated = ref(false);

const onSubmitLogin = async () => {
	const validity = await v$.value.account.$validate();
	if (!validity) return;

	const loader = $loading.show();
	const status = await login();

	if (!status?.loginStatus && status?.isPhoneConfirmError) openTACModal(status?.isMigrated);
	if (!status?.loginStatus && status?.isMigrated) openTACModal(status?.isMigrated);
	if (status?.loginStatus) clearForm();

	loader.hide();
};

const login = async () => {
	return await store
		.dispatch("identityServer/fetchLoginToken", {
			phoneNo: phoneNo.value,
			password: password.value,
		})
		.then((res) => {
			if (res?.loginStatus) toast.success(t("toast.loginSuccess"), { ...toastOptionSuccess, timeout: 1500 });
			if (!res?.loginStatus && !res?.isPhoneConfirmError && !res?.isMigrated)
				toast.error(t("toast.loginFailed"), toastOptionError);
			return res;
		})
		.catch((err) => {
			toast.error(t("toast.loginFailed"), toastOptionError);
		});
};

const openTACModal = (migratedStatus) => {
	isMigrated.value = migratedStatus;
	tacModal.value?.openModal();
};

const clearForm = async () => {
	phoneNo.value = "";
	password.value = "";
	isMigrated.value = false;

	await nextTick();
	v$.value.$reset();
};

// ================= Form End =================

// ================= Validation Start =================

const TAC_LENGTH = 6;

const rules = computed(() => ({
	account: {
		phoneNo: {
			required: helpers.withMessage(() => t("validation.required"), required),
			numeric: helpers.withMessage(() => t("validation.numeric"), numeric),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(countryData[country].MIN_PHONE_LENGTH)
			),
			maxLength: helpers.withMessage(
				({ $params }) => t("validation.maxLength", { maxLength: $params.max }),
				maxLength(countryData[country].MAX_PHONE_LENGTH)
			),
		},
		password: {
			required: helpers.withMessage(() => t("validation.required"), required),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(authField.MIN_PASSWORD_LENGTH)
			),
			maxLength: helpers.withMessage(
				({ $params }) => t("validation.maxLength", { maxLength: $params.max }),
				maxLength(authField.MAX_PASSWORD_LENGTH)
			),
		},
	},
}));

const v$ = useVuelidate(rules, {
	account: { phoneNo, password },
});

// ================= Validation End =================
</script>

<style scoped lang="sass">
:deep(.inputError)
	position: absolute
	font-size: 0.8em
.headerLoginForm
	display: flex
	align-items: center
	gap: 0.75rem
.loginButton, .registerButton
	height: 2.5rem
	min-width: 7rem
	width: fit-content
	display: flex
	align-items: center
	justify-content: center
	padding: 0
.registerButton
	animation: glow 1.5s infinite
</style>
