import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { BankStateInterface } from "./state";
import { DepositBankResponse } from "@/types/Bank/Response/DepositBankResponse";
import { WithdrawalBankResponse } from "@/types/Bank/Response/WithdrawalBankResponse";

export const getters: GetterTree<BankStateInterface, RootState> = {
	getDepositBank(state): DepositBankResponse[] {
		return state.depositBankList;
	},
	getWithdrawalBank(state): WithdrawalBankResponse[] {
		const options = [];
		state.withdrawalBankList.sort((a, b) => {
			if (a.sequence === null && b.sequence === null) {
				return 0;
			} else if (a.sequence === null) {
				return 1;
			} else if (b.sequence === null) {
				return -1;
			}
			return a.sequence - b.sequence;
		});
		state.withdrawalBankList.forEach(function (bank) {
			options.push({
				item: bank.id,
				bankName: bank.bankName,
				url: bank.picUrl,
			});
		});
		
		return options;
	},
};
