<!-- Use this component to launch game -->

<template>
	<span
		class="unifiedGameLauncher"
		:class="{ cursorPointer: !disabled && !vendor?.maintenance && !vendor?.restricted }"
		@click="handleClick"
	>
		<slot> </slot>
	</span>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useLoading } from "vue-loading-overlay";
import { useToast } from "vue-toastification";
import {
	toastOptionSuccess,
	toastOptionError,
	toastOptionInfo,
	toastOptionWarning,
} from "@/composables/useToastOptions";
import { refreshAllBalance } from "@/composables/useBalanceTransfer";
import { isLogin } from "@/composables/useAuth";
import { useIsMobile } from "@/composables/useMobile";
import { routeData } from "@/constant";
import { GameType } from "@/common/GameType";
import {
	loginPlaytechLive,
	calloutLoginPlaytech,
	launchPlaytechLiveCasino,
} from "@/composables/usePlaytechCasinoLaunchGame";
import { isFeatureEnabled } from "@/constant";
import store from "@/store/index";

const { t, locale } = useI18n();
const router = useRouter();
const route = useRoute();
const isMobile = useIsMobile();
const $loading = useLoading();
const toast = useToast();

const login = routeData.find((item) => item.title == "login");
const quickTransfer = routeData.find((item) => item.title == "quicktransfer");

const props = defineProps({
	// 'game' or 'vendor' or 'apk'
	objectType: {
		type: String,
		required: true,
	},
	// must include if objectType == 'game'
	game: {
		type: Object,
		required: false,
	},
	// must include if objectType == 'vendor'
	vendor: {
		type: Object,
		required: false,
	},
	// must include if objectType == 'apk'
	apkUrl: {
		type: String,
		required: false,
	},
	category: {
		type: Object,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["clickGame"]);

// ================= Window Start =================

// store.dispatch("window/openNewWindow", { loading: true });
// store.dispatch("window/setNewWindow", { url: "https://www.google.com" });
// store.dispatch("window/closeNewWindow");

// ================= Window End =================

// ================= Launch Game Start =================

const gameListTypes = ["SL", "FH", "CF", "LT", "FG"];
const directLaunchExceptions = ["LUCKY365"];

const isPlayTech = computed(
	() => props.vendor?.name.includes("PLAYTECH") || props.game?.vendorName.includes("PLAYTECH")
);

const handleClick = () => {
	if (props.disabled) return;
	// Maintenance
	if (props.vendor?.maintenance) {
		toast.error(t("toast.gameUnderMaintenance"), toastOptionError);
		return;
	}
	// Restricted
	if (props.vendor?.restricted) {
		toast.warning(t("toast.vendorRestricted"), toastOptionWarning);
		return;
	}

	emit("clickGame");
	if (
		props.objectType == "vendor" &&
		!directLaunchExceptions.includes(props.vendor?.name) &&
		gameListTypes.includes(props.category?.shortCode)
	)
		goToVendorPage();
	else initGame();
};

const goToVendorPage = () =>
	router.push({
		path: props.category?.name?.toLowerCase().replaceAll(" ", ""),
		query: { vendorId: props.vendor?.id || props.game?.vendorId },
	});

const autoTransfer = computed(
	() => isFeatureEnabled["FORCE_AUTO_TRANSFER"] || store.getters["member/getMember"]?.autoTransfer
);
const redirectUrl = ref("");

const initGame = async () => {
	// Authentication
	if (!isLogin.value) {
		router.replace({ query: { ...route.query, modal: "login" } });
		return;
	}

	// Maintenance
	if (props.vendor?.maintenance) {
		toast.error(t("toast.gameUnderMaintenance"), toastOptionError);
		return;
	}

	// Restricted
	if (props.vendor?.restricted) {
		toast.warning(t("toast.vendorRestricted"), toastOptionWarning);
		return;
	}

	// New window must be opened before calling async functions
	if (!isPlayTech.value && autoTransfer.value)
		store.dispatch("window/openNewWindow", { windowName: "game_window", loading: true });

	// Check maintenance again in real time
	const vendorInstance = await store.dispatch("vendors/fetchVendorWithName", {
		vendorName: props.vendor?.name || props.game?.vendorName,
	});
	const realTimeMaintenance = vendorInstance?.[0].maintenance;
	if (realTimeMaintenance && autoTransfer.value) {
		store.dispatch("window/closeNewWindow", { windowName: "game_window" });
		toast.error(t("toast.gameUnderMaintenance"), toastOptionError);
		return;
	}

	// Fetch game URL
	const loader = $loading.show();
	if (!isPlayTech.value && props.objectType != "apk") redirectUrl.value = await fetchLaunchGameUrl();
	if (!isPlayTech.value && props.objectType == "apk") redirectUrl.value = props.apkUrl;
	console.log(redirectUrl.value);
	loader.hide();

	// Close new window if URL is not available
	if (!isPlayTech.value && autoTransfer.value && !redirectUrl.value)
		store.dispatch("window/closeNewWindow", { windowName: "game_window" });

	// URL not found
	if (!isPlayTech.value && !redirectUrl.value) return;

	// Auto transfer off
	if (!autoTransfer.value) {
		goToQuickTransfer();
		return;
	}

	// Auto transfer on
	if (autoTransfer.value) {
		const loader = $loading.show();
		await transferAllAmount();
		refreshAllBalance();

		if (!isPlayTech.value)
			store.dispatch("window/setNewWindow", { windowName: "game_window", url: redirectUrl.value });
		else playtechLaunchGame();

		loader.hide();
		return;
	}
};

const fetchLaunchGameUrl = async () =>
	await store
		.dispatch("games/fetchLaunchGameUrl", {
			vendorId: props.vendor?.id || props.game?.vendorId,
			GameType: props.category?.shortCode,
			gameCode: props.game?.gameCode,
			Lang: locale.value,
			isMobile: isMobile.value,
		})
		.then((url) => {
			if (!url) toast.error(t("toast.urlNotFound"), toastOptionError);
			return url;
		})
		.catch((err) => {
			toast.error(t("toast.launchGameError", { error: err?.response?.data.title }), toastOptionError);
			return false;
		});

const goToQuickTransfer = () =>
	router.replace({
		query: {
			...route.query,
			modal: "quicktransfer",
			gameType: props.category?.shortCode,
			gameCode: props.game?.gameCode,
			redirectUrl: encodeURIComponent(redirectUrl.value),
			vendorId: props.vendor?.id || props.game?.vendorId,
		},
	});

const transferAllAmount = async () => {
	// await store.dispatch("payment/transferAllBalToMain");
	// .then((res) => {
	// 	if (res.transferAllBalToMainResponses.length > 0)
	// 	toast.error(t("toast.oneOrMoreVendorHasTurnover"), { ...toastOptionError, timeout: false });
	// 	if (res.vendorTransferSuccessLists.some((item) => item.errorMsg))
	// 	toast.error(t("toast.oneOrMoreTransfersFailed"), { ...toastOptionError, timeout: false });
	// });
	// .catch((err) =>
	// 	toast.error(t("toast.transferFailed", { error: err?.response?.data.title }), {
	// 		...toastOptionError,
	// 		timeout: false,
	// 	})
	// );

	await store.dispatch("payment/vendorsBalanceAndTransfer", {
		vendorTo: props.vendor?.name || props.game?.vendorName,
	});
	// .then((res) => {
	// 	if (!res.result)
	// 		toast.error(t("toast.transferFailed", { error: res.message }), { ...toastOptionError, timeout: false });
	// });
	// .catch((err) => {
	// 	toast.error(t("toast.transferFailed", { error: err?.response?.data.title }), {
	// 		...toastOptionError,
	// 		timeout: false,
	// 	});
	// });
};

const playtechLaunchGame = async () => {
	return await store
		.dispatch("memberAccount/fetchMemberAccount", { vendorAccountId: props.vendor?.vendorAccountId })
		.then((res) => {
			loginPlaytechLive(
				{ loginid: res.vendorGameId, password: res.vendorPassword },
				props.category?.shortCode,
				props.game?.gameCode
			);
		});
};

// ================= Launch Game End =================
</script>

<style scoped lang="sass">
.unifiedGameLauncher, .unifiedGameLauncher:hover
  display: contents
  text-decoration: none
  font-weight: revert
  margin: revert
</style>
