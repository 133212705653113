<template>
	<div class="walletBalance">
		<div class="balanceDropdownOverlay" @click="onOverlayClick" v-if="isDropdownOpen && isMobile" />

		<CustomDropdown
			:floatLeft="true"
			:toggleByHover="!isMobile"
			@open="isDropdownOpen = true"
			@close="isDropdownOpen = false"
			ref="balanceDropdown"
		>
			<template #toggle v-if="isMobile">
				<div class="balanceToggle" :class="{ active: isDropdownOpen }" tabindex="0">
					<font-awesome-icon :icon="['fas', 'coins']" />
				</div>
			</template>

			<template #toggle v-else>
				<button class="walletToggle unstyledButton" @click="refreshAllBalance" tabindex="0">
					<font-awesome-icon
						v-if="member?.loading"
						:icon="['fas', 'arrows-rotate']"
						class="refreshIcon spin"
					/>
					<div v-else class="accountCurrency">MYR</div>

					<div class="mainWalletBalance">
						{{ numberWithCommas(balance?.toFixed(2)) }}
					</div>
				</button>
			</template>

			<template #dropdown>
				<div class="categorySection" v-for="category in categoryList" :key="category.id">
					<div class="sectionTitle">
						{{ $t("category." + category.name.toLowerCase().replaceAll(" ", "")) }}
					</div>

					<div class="sectionItem" v-for="vendor in getVendorsByCategory(category.id)" :key="vendor.id">
						<span>{{ vendor.displayName }}</span>
						<div class="vendorWalletBalance">
							<span v-if="!vendor.loading">{{ vendor.memberBalance.toFixed(2) }}</span>
							<font-awesome-icon v-else :icon="['fas', 'spinner']" spin />
						</div>
					</div>
				</div>

				<UnifiedLink
					:isRouteLink="transfer.isRouteLink"
					:link="transfer.link"
					:validateLogin="transfer.validateLogin"
					:validateBank="transfer.validateBank"
				>
					<div class="transferButton mainButton" @click="onDepositClick">
						{{ t("route.transfer") }}
					</div>
				</UnifiedLink>
			</template>
		</CustomDropdown>
	</div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useIsMobile } from "@/composables/useMobile";
import { routeData } from "@/constant";
import { numberWithCommas } from "@/composables/useCountry";
import { refreshAllBalance } from "@/composables/useBalanceTransfer";
import store from "@/store/index";
import CustomDropdown from "@/components/dynamic/CustomDropdown.vue";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";

const { t, locale } = useI18n();
const isMobile = useIsMobile();

const transfer = routeData.find((item) => item.title == "transfer");
const isDropdownOpen = ref(false);

// ================= Dropdown Control Start =================

const balanceDropdown = ref(null);
const onOverlayClick = () => {
	balanceDropdown.value?.toggleDropdown("close");
};
const onDepositClick = () => {
	balanceDropdown.value?.toggleDropdown("close");
};

// ================= Dropdown Control End =================

// ================= Category & Balance Start =================

const categoryList = computed(() => store.getters["vendors/getCategoryList"]);
const member = computed(() => store.getters["member/getMember"]);
const balance = computed(() => {
	return store.getters["member/getMember"]?.balance || 0;
});

// ================= Category & Balance End =================

// ================= Vendor Start =================

const vendorList = computed(() => store.getters["vendors/getActiveMemberVendor"]());

const getVendorsByCategory = (categoryId) => {
	return vendorList.value.filter((vendor) => vendor.categories.some((category) => category.id === categoryId));
};
// ================= Vendor End =================

// ================= API Start =================

const queueFetch = () => store.dispatch("progressTracker/queueTask");
const completeFetch = () => store.dispatch("progressTracker/completeTask");

const fetchMemberActiveVendorList = async () => {
	queueFetch();
	await store.dispatch("vendors/fetchMemberActiveVendorList").finally(() => completeFetch());
};

const updateVendorBalance = async (vendorAccountId) => {
	queueFetch();
	const res = await store
		.dispatch("memberAccount/fetchVendorBalance", {
			vendorAccountId: vendorAccountId,
			isFromDB: false,
		})
		.finally(() => completeFetch());
	return res?.balance;
};

const updateVendorBalanceSequential = async () => {
	for (const vendor of vendorList.value) {
		updateVendorBalance(vendor.vendorAccountId);
	}
};

// ================= API End =================
</script>

<style scoped lang="sass">
.walletBalance
	cursor: default
.balanceDropdownOverlay
	position: absolute
	top: 0
	left: 0
	height: 100vh
	width: 100vw
	z-index: 30
.balanceToggle
	font-size: 1.5em
	color: var(--text-accent-light)
	&.active
		color: var(--color-accent)
.walletToggle
	display: flex
	align-items: baseline
	gap: 0.25rem
	background: var(--gradient-secondary)
	border-radius: 999rem
	padding: 0 0.75rem
.accountCurrency
	font-weight: var(--font-medium)
	font-size: 0.8em
.mainWalletBalance
	font-weight: var(--font-bold)
	font-size: 0.9em
.refreshIcon
	font-size: 0.8em
	&.spin
		animation-name: spinZ
		animation-duration: 1s
		animation-iteration-count: infinite
		animation-timing-function: linear


.dropdownRefreshButton
	margin-bottom: 0.8rem
	padding-left: 2rem
	text-align: center
	.mainWallet
		font-weight: var(--font-bold)
.categorySection
	display: flex
	flex-direction: column
	padding: 0.5rem 0.2rem
	&:not(:last-of-type)
		border-bottom: 1px solid var(--border-regular)
.sectionTitle
	font-size: 0.8em
	color: var(--text-light)
	padding: 0.1rem 0.5rem
.sectionItem
	display: flex
	justify-content: space-between
	padding: 0 0.5rem
	gap: 0.75rem
	font-weight: var(--font-medium)
.transferButton
	text-align: center
	border-radius: unset
	padding: 0.5rem

// :deep(.toggleContainer)
// 	display: flex
// 	align-items: center
:deep(.dropdownContainer .dropdownPanel)
	min-width: 17.5rem
	max-height: 80vh
	padding-block: unset

// ====================== TRANSITION ======================
// :deep(.dropdown-enter-active)
// 	transition: all 0.3s ease-in-out
// :deep(.dropdown-leave-active)
// 	transition: all 0.3s ease-in-out
// :deep(.dropdown-enter-from)
// 	transform: translateY(-20px)
// 	opacity: 0
// :deep(.dropdown-leave-to)
// 	transform: translateY(-20px)
// 	opacity: 0
// ====================== TRANSITION ======================
</style>
