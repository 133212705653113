
// import { HubConnectionBuilder } from "@aspnet/signalr";
import { defineComponent } from "vue";
import { RootMutations } from "@/store/mutations";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { routeData, isFeatureEnabled } from "@/constant";
import {
	toastOptionSuccess,
	toastOptionError,
	toastOptionInfo,
	toastOptionWarning,
} from "@/composables/useToastOptions";
import { useI18n } from "vue-i18n";
import { languageCodeToId } from "@/composables/useCulture";
import { Ticks } from "chart.js";
import { useVibrate } from "@vueuse/core";
import { useLogout } from "@/composables/useAuth";

export default defineComponent({
	data() {
		return {
			connection: new HubConnectionBuilder().withUrl(`${process.env.VUE_APP_Signalr_Connection}`).build(),
			// toastPosition: this.$isMobile() ? "bottom-center" : "top-center",
			statement: routeData.find((item) => item.title == "statement"),
		};
	},
	async mounted() {
		await this.start();
		this.onReceiveMemberRefreshBalance();
		this.onReceiveMemberTurnoverRefreshBalance();
		this.onReceiveMemberWinoverRefreshBalance();
		this.onApproveTicket();
		this.onRejectTicket();
		this.onReceivedInboxNotification();
		this.onReceiveUserSessionEndNotification();
		if (isFeatureEnabled["MINIGAME"]) this.onReceiveUserMiniGameTicketUpdateNotification();
		this.connection.onclose(this.start);
	},
	methods: {
		async start() {
			try {
				await this.connection.start();
				this.addToGroup();
				this.addSIDToGroup();
			} catch (err) {
				setTimeout(() => {
					this.start();
				}, 5000);
			}
		},
		async addToGroup() {
			const loginId = this.$store.getters["identityServer/getLoginId"];
			const clientId = process.env.VUE_APP_IdentityServer_ClientId;
			const sId = this.$store.getters["identityServer/getSId"];
			const connectionIDString = `${clientId}_${loginId}`;
			await this.connection
				.invoke("AddToGroup", `${process.env.VUE_APP_Signalr_Connection}`, connectionIDString)
				.then(function () {
					console.log("added to group " + connectionIDString);
				})
				.catch(function (err) {
					console.log(err);
				});
		},
		async addSIDToGroup() {
			const sId = this.$store.getters["identityServer/getSId"];
			const connectionIDString = `${sId}`;
			await this.connection
				.invoke("AddToGroup", `${process.env.VUE_APP_Signalr_Connection}`, connectionIDString)
				.then(function () {
					console.log("added to group " + connectionIDString);
				})
				.catch(function (err) {
					console.log(err);
				});
		},
		async onReceiveMemberRefreshBalance() {
			this.connection.on("ReceiveMemberRefreshBalanceTrigger", () => {
				this.$store.dispatch("member/fetchMember");
			});
		},
		async onReceiveMemberTurnoverRefreshBalance() {
			const loginId = this.$store.getters["identityServer/getLoginId"];
			this.connection.on("ReceiveMemberTurnoverRefreshTrigger", () => {
				this.$store.dispatch("memberAccount/fetchMemberTurnover", {
					loginId: loginId,
					isFromDB: true,
				});
			});
		},
		async onReceiveMemberWinoverRefreshBalance() {
			const loginId = this.$store.getters["identityServer/getLoginId"];
			this.connection.on("ReceiveMemberWinoverRefreshTrigger", () => {
				this.$store.dispatch("memberAccount/fetchMemberWinover", {
					loginId: loginId,
					isFromDB: true,
				});
			});
		},
		async onApproveTicket() {
			this.connection.on("ApproveTicketTrigger", (ticketId: string) => {
				//add in code
				this.toast.info(this.$t("toast.ticketSuccess", { id: ticketId }), {
					// position: this.toastPosition,
					...toastOptionInfo,
					timeout: 8000,
					id: "t_" + ticketId,
					onClose: () => {
						this.$store.dispatch("member/fetchMember");
						this.$store.dispatch("bonus/fetchActiveBonusAppliedWithBonusVendor");
						this.$store.dispatch("memberGroup/fetchMemberCurrentTier");
						this.$store.dispatch("events/fetchMemberLoginEvent");
						//fetch Recheck In
						// this.$router.push({ path: '/statement' })
					},
					onClick: () => this.redirectToStatement(ticketId),
				});
				this.pushNotification(this.$t("toast.ticketSuccess", { id: ticketId }));
				this.vibrateDevice();
			});
		},
		async onRejectTicket() {
			this.connection.on("RejectTicketTrigger", (ticketId: string) => {
				//add in code
				this.toast.error(this.$t("toast.ticketReject", { id: ticketId }), {
					// position: this.toastPosition,
					...toastOptionError,
					id: "t_" + ticketId,
					timeout: 8000,
					onClose: () => {
						this.$store.dispatch("member/fetchMember");
						this.$store.dispatch("bonus/fetchActiveBonusAppliedWithBonusVendor");
						this.$store.dispatch("memberGroup/fetchMemberCurrentTier");
						this.$store.dispatch("events/fetchMemberLoginEvent");
						//fetch Recheck In
						// this.$router.push({ path: '/statement' })
					},
					onClick: () => this.redirectToStatement(ticketId),
				});
				this.pushNotification(this.$t("toast.ticketReject", { id: ticketId }));
				this.vibrateDevice();
			});
		},
		async onReceivedInboxNotification() {
			this.connection.on("ReceiveMemberInboxNotificationTrigger", (memberInboxId: string, inboxType: string) => {
				//add in code
				// toastOptionInfo.onClick = "/inbox";
				this.$store.dispatch("inbox/fetchMemberInbox", { languageId: languageCodeToId[this.$i18n.locale] });
				this.toast.info(this.$t("toast.newInboxReceived"), {
					// position: this.toastPosition,
					...toastOptionInfo,
					id: "t_" + memberInboxId,
					timeout: 8000,
					onClick: () => this.redirectToInbox(memberInboxId),
				});
				this.pushNotification(this.$t("toast.newInboxReceived"));
				this.vibrateDevice();
			});
		},
		async onReceiveUserSessionEndNotification() {
			this.connection.on("ReceiveUserSessionEndNotification", () => {
				this.toast.warning(this.$t("toast.accountAccessed"), {
					// position: this.toastPosition,
					...toastOptionWarning,
					timeout: 5000,
					hideProgressBar: false,
					pauseOnFocusLoss: false,
					pauseOnHover: false,
					onClick: () => {
						useLogout(false, true);
						window.location.reload();
					},
					onClose: () => {
						useLogout(false, true);
						window.location.reload();
					},
				});
			});
		},
		async onReceiveUserMiniGameTicketUpdateNotification() {
			this.connection.on("ReceiveUserMiniGameTicketUpdateNotification", () => {
				//add in code
				this.$store.dispatch("miniGames/fetchMiniGamesUser");
			});
		},
		async SendAccountCreationNotification() {
			this.connection.on("SendAccountCreationNotification", () => {
				//add in code
				this.$store.commit(RootMutations.SET_VENDOR_REGISTER_STATUS, true);
			});
		},
		redirectToInbox(memberInboxId) {
			this.$router.push({ path: "/inbox", query: { id: memberInboxId } });
			this.toast.dismiss("t_" + memberInboxId);
		},
		redirectToStatement(ticketId) {
			this.$router.push({ path: "/statement", query: { type: "deposit", search: ticketId } });
			this.toast.dismiss("t_" + ticketId);
		},
		pushNotification(title) {
			if (window.Notification) {
				if (Notification.permission === "granted") {
					const newNotification = new Notification(title);
				}
			}
		},
		vibrateDevice() {
			try {
				const { vibrate, stop, isSupported } = useVibrate({ pattern: [300, 100, 300] });
				if (isSupported) {
					vibrate();
				}
			} catch (err) {
				console.log("This device does not support vibration");
			}
		},
		async disconnectSignalr() {
			await this.connection.stop();
		},
	},
	computed: {
		getDisconnectSignalr() {
			return this.$store.getters["identityServer/getDisconnectSignalr"];
		},
	},
	watch: {
		async getDisconnectSignalr(newVal, oldVal) {
			if (newVal) {
				await this.disconnectSignalr();
				await this.$store.dispatch("identityServer/setDisconnectSignalr", false);
			}
		},
	},
});
