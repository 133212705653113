export default {
	RESPONSIBLEGAMING: "游戏责任",
	Description:
		"我们希望我们的用户在 NSK8 享受到乐趣，因此我们鼓励负责任博彩。我们允许玩家制定自己的负责任博彩限制，然后我们会通过管理工具帮助设立和控制这些自定义限制。 博彩是娱乐的一种形式，同时不应该给您财务和感情生活带来负担。借钱来玩，花费超出预算或挪用其他用途的金钱不仅是不明智的，而且会您和您身边的人带来严重的问题。我们希望您在 NSK8 享受到乐趣，因此请负责任地投注并玩得开心！",
	GettingAssistance: "寻求协助",
	Description1:
		"有很多机构可以为在博彩中产生问题的人提供支持和帮助，我们推荐这些玩家联络这些自助组织获得额外的帮助。 以下网站提供建议和指引。每个组织都有帮助热线和电子邮件地址，如果您需要严格保密的建议和帮助，您可以联络他们。",
	Description2:
		"<ul><li>www.gamblersanonymous.org</li><li>www.gamcare.org.uk</li><li>www.gamblingtherapy.org</li></ul>",
	Doyouhaveagamblingproblem: "您是否有嗜赌方面的问题？",
	Description3: "如果您感到您可能会有嗜赌方面的问题，请问一下您自己一下问题：",
	DescGambling1:
		"<ul><li>有无人曾经批评过您赌博？</li><li>您是否曾经因为试图掩饰您在赌博方面花费的时间和金钱而撒谎？</li><li>争吵、挫折感和失望令您想进行赌博？</li><li>您是否独自一人长时间赌博？</li><li>您是否远离工作、同事或学校进行赌博？</li><li>您赌博是因为摆脱无聊或生活的不快乐吗？</li><li>您是否很不情愿将赌博用的钱使用在其他地方</li><li>您是否因为赌博而对家庭、朋友和其他消遣失去兴趣？</li><li>输钱之后，您是否感觉您必须再次尝试并尽可能赢回您输掉的钱？</li><li>当您赌博并输掉所有钱之后，您会否感到失落和绝望，并需要尽可能再赌一次？</li><li>您会赌到输掉最后一分钱吗？</li><li>您是否曾经撒谎，偷窃或借钱来取得资金进行赌博或还赌债？</li><li>您是否因为赌博感觉沮丧甚至想自杀？</li></ul>",
	Description4: "您回答“是的”越多，越表明您可能有严重的博彩问题，并可能需要寻求以上列出的渠道的帮助和建议。",
	Managingyourplaying: "管理您的博彩方法",
	Description5:
		"<ul><li>对于经常享受博彩乐趣的玩家来说，有时花费超过预算并不罕见。我们建议您制定特定的预算计划以确保您的博彩花费是可以负担的。</li><li>有时人们会否认有问题，并只有在受到批评时才寻求帮助。坦率地问您自己，并且您是否认为您能够在遇到博彩问题是为自己设立一个目标，例如两周或一个月，停止博彩作为试验。如果您不能做到这点，您可能真的有问题，您可能需要与您的顾问讨论一下您的处境。</li></ul>",
	Preventingunderagegambling: "防止未成年人博彩",
	Description6:
		"您必须年满18岁才能在 NSK8 玩。我们会进行检查以确保没有未成年人访问我们的博彩网站。提供不准确或不诚实的玩家年龄资料将会导致没收赢利的后果甚至法律诉讼的后果。",
};
